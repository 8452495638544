.ag-theme-custom {
  --ag-background-color: #15192e !important;
  --ag-odd-row-background-color: #111324 !important;
  --ag-header-background-color: #111324 !important;
  --ag-selected-row-background-color: #b20a67;
  --ag-range-selection-border-color: #b20a67;
  --ag-checkbox-checked-color: #b20a67;
  --ag-row-hover-color: #333548;
  --ag-input-focus-border-color: #b20a67;
  --ag-borders: 0;
}